<div *ngIf="isLoading">Loading org...</div>
<div *ngIf="!isLoading">
  <div *ngIf="organizationDetails">
    <h2>{{ organizationDetails[0].name }}</h2>
    <!-- Organization Details Component Template -->
    <div class="details-container">
      <!-- Address Information Box -->
      <div class="info-box address-info">
        <div class="content">
          <h3>Address Information</h3>
          <p>{{ organizationDetails[0].street_1 }}</p>
          <p>{{ organizationDetails[0].street_2 }}</p>
          <p>
            {{ organizationDetails[0].city }} {{ organizationDetails[0].state }}
            {{ organizationDetails[0].postal_code }}
          </p>
        </div>
        <button
          class="edit-button"
          (click)="openModal('address', organizationDetails[0])"
        >
          Edit
        </button>
      </div>

      <!-- Organization Owner Information Box -->
      <div class="info-box owner-info">
        <div class="content">
          <h3>Owner Information</h3>
          <p>
            Owner: {{ organizationDetails[0].owner_firstname }}
            {{ organizationDetails[0].owner_lastname }}
          </p>
          <p>Phone: {{ organizationDetails[0].owner_phone_num }}</p>
          <p>Email: {{ organizationDetails[0].owner_email }}</p>
        </div>
        <button
          class="edit-button"
          (click)="openModal('owner', organizationDetails[0])"
        >
          Edit
        </button>
      </div>

      <!-- Operator Pin Box -->
      <div class="info-box pin-box">
        <div class="content">
          <h3>Operator Key</h3>
          <h3>{{ organizationDetails[0].operator_pin }}</h3>
        </div>
      </div>
    </div>

    <!-- Control Buttons Container -->
    <div class="control-buttons">
      <!-- Toggle Users Button -->
      <button (click)="toggleUsersVisibility()" class="btn btn-info">
        {{ showUsers ? 'Hide Users' : 'Show Users' }}
        <img
          [src]="'assets/img/dropdown.png'"
          [class.flipped]="showUsers"
          alt="Toggle Dropdown"
        />
      </button>
    </div>

    <div class="content-area">
      <!-- Users Table -->
      <div [@expandCollapse]="showUsers ? 'expanded' : 'collapsed'">
        <app-manage-users></app-manage-users>
      </div>
    </div>

    <div class="control-buttons">
      <!-- Toggle Machines Button -->
      <button (click)="toggleMachinesVisibility()" class="btn btn-info">
        {{ showMachines ? 'Hide Machines' : 'Show Machines' }}
        <img
          [src]="'assets/img/dropdown.png'"
          [class.flipped]="showUsers"
          alt="Toggle Dropdown"
        />
      </button>
    </div>

    <!-- Content Area for Users and Machines -->
    <div class="content-area">
      <!-- Machines Table -->
      <div [@expandCollapse]="showMachines ? 'expanded' : 'collapsed'">
        <app-manage-machines></app-manage-machines>
      </div>
    </div>

    <div *ngIf="isModalOpen" class="modal">
      <div class="modal-content">
        <span class="close" (click)="isModalOpen = false">&times;</span>
        <form [formGroup]="editForm" (ngSubmit)="submitEdit()">
          <!-- Conditionally display fields based on the modalContext -->
          <div *ngIf="modalContext === 'address'">
            <div class="form-group">
              <label>Street</label>
              <input formControlName="street_1" type="text" />
              <label>Apartment, suite, etc</label>
              <input formControlName="street_2" type="text" />
              <label>City</label>
              <input formControlName="city" type="text" />
              <label>State</label>
              <input formControlName="state" type="text" />
              <label>Postal Code</label>
              <input formControlName="postal_code" type="text" />
            </div>
          </div>
          <div *ngIf="modalContext === 'owner'">
            <div class="form-group">
              <label>First Name</label>
              <input formControlName="owner_firstname" type="text" />
              <label>Last Name</label>
              <input formControlName="owner_lastname" type="text" />
              <label>Phone Number</label>
              <input formControlName="owner_phone_num" type="text" />
            </div>
          </div>
          <button type="submit">Save Changes</button>
        </form>
      </div>
    </div>
  </div>
</div>
