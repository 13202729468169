import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule as MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';

import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { AuthModule } from '@angular/fire/auth';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule } from '@angular/common/http';
import { FormatterService } from './services/formatter';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { ManageOrganizationsComponent } from './components/manage-organizations/manage-organizations.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ManageUsersModule } from './components/users/manage-users/manage-users.module';
import { ManageMachinesComponent } from './components/manage-machines/manage-machines.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {
  connectFunctionsEmulator,
  getFunctions,
  provideFunctions,
} from '@angular/fire/functions';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    NotificationsComponent,
    ManageOrganizationsComponent,
    ManageMachinesComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    AppRoutingModule,
    BrowserModule,
    MatListModule,
    MatSidenavModule,
    MatDividerModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    NgxSpinnerModule,
    MatMenuModule,
    MatIconModule,
    MatSnackBarModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFunctions(() => {
      const functions = getFunctions();
      if (environment.name === 'local') {
        console.log('Running local cloud functions!!!');
        connectFunctionsEmulator(functions, 'localhost', 5005);
      }

      return functions;
    }),
    AuthModule,
    HttpClientModule,
    ReactiveFormsModule,
    ManageUsersModule,
    NgxDatatableModule,
    MatGoogleMapsAutocompleteModule.forRoot(
      'AIzaSyA57KwZwVx1sIXPJRXrJqQskYhwKYpZbew'
    ),
  ],
  providers: [{ provide: ErrorHandler }, FormatterService],
  bootstrap: [AppComponent],
  exports: [ManageMachinesComponent],
})
export class AppModule {}
