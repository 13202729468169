import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToolbarService implements OnInit {
  _showSideNav: boolean = false;

  _showToolbar = false;

  get showSideNav() {
    return this._showSideNav;
  }
  set showSideNav(x) {
    console.log('setting showSideNav to ', x);
    this._showSideNav = x;
  }

  get showToolbar() {
    return this._showToolbar;
  }

  set showToolbar(x) {
    this._showToolbar = x;
  }

  constructor() {}

  ngOnInit() {}
}
