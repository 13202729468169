<div class="toolbar">
  <app-toolbar></app-toolbar>
</div>
<div class="sidenav">
  <mat-sidenav-container>
    <mat-sidenav
      [mode]="isMobile ? 'over' : 'side'"
      [opened]="opened"
      style="background-color: gold"
      fixedInViewport="true"
    >
      <div class="navigate-urls">
        <mat-selection-list [multiple]="false">
          <a mat-list-item routerLink="/portal/dashboard">
            <span style="font-weight: bold">Dashboard</span>
          </a>
          <a mat-list-item routerLink="/portal/organizations">
            <span style="font-weight: bold">Organizations</span>
          </a>
          <a mat-list-item routerLink="/portal/billing">
            <span style="font-weight: bold">Billing</span>
          </a>
          <a
            *ngIf="
              !authGaurd.isBruscoAccount() && !authGaurd.isArthurRayAccount()
            "
            mat-list-item
            routerLink="/portal/identity-search"
          >
            <span style="font-weight: bold">Identities</span>
          </a>
          <a mat-list-item routerLink="/portal/reports">
            <span style="font-weight: bold">Reports</span>
          </a>
          <a
            *ngIf="
              !authGaurd.isBruscoAccount() && !authGaurd.isArthurRayAccount()
            "
            mat-list-item
            routerLink="/portal/blacklisted"
          >
            <span style="font-weight: bold">Blocklist</span>
          </a>
          <a
            *ngIf="
              !authGaurd.isBruscoAccount() && !authGaurd.isArthurRayAccount()
            "
            mat-list-item
            routerLink="/portal/kyc"
          >
            <span style="font-weight: bold">KYC</span>
          </a>
          <a
            *ngIf="
              !authGaurd.isBruscoAccount() && !authGaurd.isArthurRayAccount()
            "
            mat-list-item
            routerLink="/portal/ctr"
          >
            <span style="font-weight: bold">CTR Transactions</span>
          </a>
          <a mat-list-item routerLink="/portal/cashbox-logs">
            <span style="font-weight: bold">Cashbox Logs</span>
          </a>
          <a
            *ngIf="
              !authGaurd.isBruscoAccount() && !authGaurd.isArthurRayAccount()
            "
            mat-list-item
            routerLink="/portal/tx-server"
          >
            <span style="font-weight: bold">TX Server</span>
          </a>
          <a
            *ngIf="
              !authGaurd.isBruscoAccount() && !authGaurd.isArthurRayAccount()
            "
            mat-list-item
            routerLink="/portal/locations"
          >
            <span style="font-weight: bold">Locations</span>
          </a>
          <a
            *ngIf="
              !authGaurd.isBruscoAccount() && !authGaurd.isArthurRayAccount()
            "
            mat-list-item
            routerLink="/portal/eddlogs"
          >
            <span style="font-weight: bold">EDD Requests</span>
          </a>
          <a
            *ngIf="
              !authGaurd.isBruscoAccount() && !authGaurd.isArthurRayAccount()
            "
            mat-list-item
            routerLink="/portal/users"
          >
            <span style="font-weight: bold">Manage Users</span>
          </a>
        </mat-selection-list>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="comp-page">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<!--<div class="comp-page">
  <router-outlet></router-outlet>
</div>-->
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="gold"
  type="ball-atom"
  [fullScreen]="true"
  ><p style="color: white">Loading...</p>
  <!-- type="ball-pulse-sync" 
  type="ball-pulse-sync"
  
  -->
</ngx-spinner>
