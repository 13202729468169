import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthGuard } from './services/auth.guard';
import { ToolbarService } from './toolbar.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  isMobile: boolean = false;
  get opened() {
    return this._toolbar.showSideNav || false;
  }
  constructor(
    public _toolbar: ToolbarService,
    public authGaurd: AuthGuard,
    private breakpointObserver: BreakpointObserver,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isMobile = result.matches;
        this._cd.detectChanges();
      });
  }
}
