<div *ngIf="isLoading">Loading users...</div>
<div *ngIf="!isLoading && users.length === 0">No users found.</div>
<div *ngIf="!isLoading && users.length > 0" class="search-container">
  <input
    type="text"
    placeholder="Search users..."
    [(ngModel)]="searchTerm"
    (keyup)="filterUsers()"
  />
  <button class="add-user-button" (click)="openModal('add')">
    Add New User
  </button>
</div>
<div class="container">
  <div [class.shrink]="selectedUser" class="table-container">
    <table *ngIf="!isLoading && users.length > 0">
      <thead>
        <tr>
          <th>
            <div class="header-name">
              Name
              <button
                (click)="setSortField('display_name')"
                [class.active-sort]="sortField === 'display_name'"
              >
                <div class="sort-labels">
                  <span class="sort-text">A</span>
                  <span class="sort-text">Z</span>
                </div>
                <span class="sort-arrow">{{
                  sortField === 'display_name'
                    ? sortOrder === 'asc'
                      ? '↑'
                      : '↓'
                    : '↑↓'
                }}</span>
              </button>
            </div>
          </th>
          <th>
            <div class="header-name">
              Email
              <button
                (click)="setSortField('email')"
                [class.active-sort]="sortField === 'email'"
                [class.inactive-sort]="sortField !== 'email'"
              >
                <div class="sort-labels">
                  <span class="sort-text">A</span>
                  <span class="sort-text">Z</span>
                </div>
                <span class="sort-arrow">{{
                  sortField === 'email'
                    ? sortOrder === 'asc'
                      ? '↑'
                      : '↓'
                    : '↑↓'
                }}</span>
              </button>
            </div>
          </th>
          <th>Phone Number</th>
          <th>Organization</th>
          <th>
            Role
            <select [(ngModel)]="selectedRoleFilter" (change)="filterUsers()">
              <option value="">All Roles</option>
              <option *ngFor="let role of roles" [value]="role.code">
                {{ role.description }}
              </option>
            </select>
          </th>
          <th>Last Login</th>
          <th>Created</th>
          <th>Crypto Allow</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let user of filteredUsers"
          (click)="openModal('edit', user)"
          [ngClass]="{ 'selected-row': selectedUser === user }"
        >
          <td>{{ user.display_name }}</td>
          <td>
            {{ user.email
            }}<span
              [ngStyle]="{ color: user.email_verified ? 'green' : 'red' }"
              >{{ user.email_verified ? ' ✓' : ' ✗' }}</span
            >
          </td>
          <td>{{ formatPhone(user.phone_num) }}</td>
          <td>{{ user.organization_name }}</td>
          <td>{{ getRoleDescription(user.role) }}</td>
          <td>
            {{
              user.last_login_at
                ? convertTimestampToDate(user.last_login_at)
                : ''
            }}
          </td>
          <td>
            {{ user.created_on ? convertTimestampToDate(user.created_on) : '' }}
          </td>
          <td>
            <div *ngIf="user.enable_crypto">
              <!--Adjust later to only show cryptos the org allows-->
              <img
                *ngFor="let img of cryptoImages"
                [src]="img.src"
                [alt]="img.alt"
                class="crypto-icon"
              />
            </div>
          </td>
          <!--<td>
            <button class="delete-button" (click)="deleteUser(user, $event)">Delete</button>
          </td>-->
        </tr>
      </tbody>
    </table>
  </div>

  <div class="modal" *ngIf="isModalOpen">
    <div class="modal-content">
      <span class="close" (click)="closeModal()">&times;</span>
      <h2>
        {{ modalContext === 'add' ? 'Add New User' : 'Edit User Details' }}
      </h2>
      <form
        [formGroup]="userDetailsForm"
        (ngSubmit)="modalContext === 'add' ? addUser() : editUser()"
      >
        <div class="form-row">
          <div class="form-group">
            <label for="firstName">First Name:</label>
            <input type="text" id="firstName" formControlName="firstName" />
          </div>
          <div class="form-group">
            <label for="lastName">Last Name:</label>
            <input type="text" id="lastName" formControlName="lastName" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" id="email" formControlName="email" />
          </div>
          <div class="form-group">
            <label for="phone_num">Phone Number:</label>
            <input type="text" id="phone_num" formControlName="phone_num" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label for="role">Role:</label>
            <select id="role" formControlName="role">
              <option *ngFor="let role of roles" [value]="role.code">
                {{ role.description }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="org">Organization:</label>
            <select id="org" formControlName="org_id">
              <option
                *ngFor="let organization of organizations"
                [value]="organization.org_id"
              >
                {{ organization.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-row align-items-center">
          <!-- Ensure alignment in a row -->
          <div class="form-group">
            <label>
              <input type="checkbox" formControlName="enable_crypto" />
              Allow Crypto
            </label>
          </div>

          <div
            *ngIf="
              modalContext === 'edit' &&
              selectedUser &&
              !selectedUser.email_verified
            "
            class="button-group"
          >
            <button type="button" (click)="resendEmail()">
              Resend Verification Email
            </button>
          </div>
          <div *ngIf="modalContext === 'edit'" class="button-group">
            <button
              type="button"
              class="delete-button"
              (click)="deleteUser($event)"
              [disabled]="isLoadingDelete"
            >
              {{ isLoadingDelete ? 'Deleting...' : 'Delete User' }}
            </button>
          </div>
        </div>

        <button type="submit" [disabled]="isLoadingAdd || isLoadingEdit">
          {{
            isLoadingAdd
              ? 'Adding...'
              : modalContext === 'add'
              ? 'Add User'
              : isLoadingEdit
              ? 'Saving...'
              : 'Save Changes'
          }}
        </button>
      </form>
    </div>
  </div>
</div>
