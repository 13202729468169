import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { FireBaseService } from './firebase';
import { Observable } from 'rxjs';
import { ToolbarService } from '../toolbar.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  vegasMachUsers: string[] = [
    'andre@anonymityholdings.com',
    'charley@anonymityholdings.com',
    'ian@anonymityholdings.com',
    'jeff@anonymityholdings.com',
    'idc@anonymityholdings.com',
    'dan@anonymityholdings.com',
    'testvegas@vegas.llc',
  ];

  bruscoMachs: string[] = [
    '00001-001-1-10005',
    '00001-001-1-00134',
    '00001-001-1-00135',
    '00001-001-1-00147',
    '00001-001-1-00148',
    '00001-001-1-00149',
    '00001-001-1-0014d',
    '00001-001-1-0014a',
    '00001-001-1-00136',
    '00001-001-1-0014e',
    '00001-001-1-0014i',
    '00001-001-1-0014j',
    '00001-001-1-0014k',
    '00001-001-1-0014l',
    '00001-001-1-0014n',
    '00001-001-1-0014m',
    '00001-001-1-0014w',
    '00001-001-1-00139',
    '00001-001-1-0015d',
    '00001-001-1-00155',
    '00001-001-1-0014o',
    '00001-001-1-0014b',
    '00001-001-1-0014s',
    '00001-001-1-0014p',
    '00001-001-1-0015l',
    '00001-001-1-10004',
    '00001-001-1-0014c',
    '00001-001-1-0014r',
  ];

  bruscoMachsForTxs: string[] = [
    '00001-001-1-10005',
    '00001-001-1-00132',
    '00001-001-1-00134',
    '00001-001-1-00135',
    '00001-001-1-00147',
    '00001-001-1-00148',
    '00001-001-1-00149',
    '00001-001-1-0014c',
    '00001-001-1-0014d',
    '00001-001-1-0014e',
    '00001-001-1-0014i',
    '00001-001-1-0014j',
    '00001-001-1-0014k',
    '00001-001-1-0014l',
    '00001-001-1-0014n',
    '00001-001-1-0014m',
    '00001-001-1-00136',
    '00001-001-1-0014w',
    '00001-001-1-10004',
    '00001-001-1-0015d',
    '00001-001-1-00155',
    '00001-001-1-0014o',
    '00001-001-1-0014b',
    '00001-001-1-0014s',
    '00001-001-1-0014r',
    '00001-001-1-0014p',
    '00001-001-1-00139',
    '00001-001-1-0015l',
    '00001-001-1-0014a',
  ];

  bruscoUserEmail = 'atmmanagement@verizon.net';

  arthurUserEmail = 'arthurray7877@gmail.com';

  arthurMachs: string[] = [
    '88888-001-8-01814',
    '88888-001-8-00902',
    '88888-001-8-00027',
    '88888-001-8-01511',
  ];

  adminEmails: string[] = [
    'nickv@freedomgateway.llc',
    'connor@freedomgateway.com',
  ];

  constructor(
    public authService: FireBaseService,
    public router: Router,
    private _toolbar: ToolbarService,
    private _firebaseAuth: AngularFireAuth
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isLoggedIn !== true) {
      this.router.navigate(['']);

      return false;
    }

    // this._toolbar.showSideNav = true;

    return true;
  }

  logout() {
    this._firebaseAuth
      .signOut()
      .then((res) => this.router.navigate(['/login']));
  }

  isVegasAccount() {
    const user = JSON.parse(localStorage.getItem('user')!);
    if (user != undefined) {
      return this.vegasMachUsers.includes(user.email);
    } else {
      return false;
    }
  }

  isBruscoAccount() {
    const user = JSON.parse(localStorage.getItem('user')!);
    if (user != undefined) {
      return this.bruscoUserEmail == user.email;
    } else {
      return false;
    }
  }

  isArthurRayAccount() {
    const user = JSON.parse(localStorage.getItem('user')!);
    if (user != undefined) {
      return this.arthurUserEmail == user.email;
    } else {
      return false;
    }
  }

  getOrg(machine?: string) {
    if (this.isVegasAccount()) {
      return '00002-002';
    } else if (machine?.includes('-002-')) {
      return '00002-002';
    } else {
      return '00001-001';
    }
  }

  isAdmin() {
    const user = JSON.parse(localStorage.getItem('user')!);
    if (user != undefined) {
      return this.adminEmails.includes(user.email);
    } else {
      return false;
    }
  }

  isBruscoMachine(machId: string) {
    return this.bruscoMachs.includes(machId);
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user')!);
  }

  getBruscoMachs() {
    return this.bruscoMachs;
  }

  getBruscoMachsForTxs() {
    return this.bruscoMachsForTxs;
  }

  getArthurMachs() {
    return this.arthurMachs;
  }

  getAccountMachs(dataSet: string) {
    const user = JSON.parse(localStorage.getItem('user')!);
    if (user.email == this.bruscoUserEmail) {
      if (dataSet == 'transactions') {
        return this.bruscoMachsForTxs;
      } else {
        return this.bruscoMachs;
      }
    } else if (user.email == this.arthurUserEmail) {
      return this.arthurMachs;
    } else {
      return [];
    }
  }
}
