export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyCGo3kRnML6XpARFICiAvSu09K9wyzNndo',
    authDomain: 'master-firebase-425a3.firebaseapp.com',
    databaseURL: 'https://master-firebase-425a3-default-rtdb.firebaseio.com',
    projectId: 'master-firebase-425a3',
    storageBucket: 'master-firebase-425a3.appspot.com',
    messagingSenderId: '415685994850',
    appId: '1:415685994850:web:2e7c2202cdf3c40fa2e43b',
    measurementId: 'G-WJQC3PME5K',
  },
  orgId: '88888-001',
  atmServerRequestUrl: 'https://fgprod.moneroatm.xyz/api/',
  fgApiUrl: '/master-firebase-425a3/us-central1/app/',
  cipherTrace: 'https://inspector.ciphertrace.com/trace/',
  googleApiKey: 'AIzaSyA57KwZwVx1sIXPJRXrJqQskYhwKYpZbew',
  fgSqlApiUrl: 'https://us-central1-master-firebase-425a3.cloudfunctions.net/',
  // fgSqlApiUrl: 'http://localhost:5005/master-firebase-425a3/us-central1/',
  name: 'stage',
  // name: 'local',
  carPublicKey: 'ndeznjixndm3mta5nmywzdqxzdu4mjg',
  carSecretKey: 'mgq2oty3zdzhogzjnmrmogrhmgu5zgq3nzuyzmnkzgqynje5yjrmmtgyoa',
};
