<mat-toolbar color="primary">
  <ng-container *ngIf="toolbar.showToolbar">
    <button
      style="background-color: black; border: none; color: gold"
      mat-icon-button
      (click)="toggleNav()"
    >
      <mat-icon color="gold">menu</mat-icon>
    </button>
  </ng-container>
  <p>Freedom Gateway Portal</p>
  <ng-container *ngIf="toolbar.showToolbar">
    <ul class="logout" style="padding-left: 5px">
      <a href="" style="font-size: smaller" (click)="logout()">Logout</a>
    </ul>
  </ng-container>
</mat-toolbar>
