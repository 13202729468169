import { Injectable, NgZone, Output, EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FileUpload } from '../models/file-upload';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { User } from '../interfaces/user';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { getStorage, ref, listAll, deleteObject } from 'firebase/storage';
import { OFAC } from '../interfaces/ofac';
import { Identity } from '../interfaces/identity';
import { MachineLog } from '../interfaces/machinelog';
import { Transactions } from '../interfaces/transactions';
import { Notes } from '../interfaces/notes';
import { Machine } from '../interfaces/machine';
import { FormatterService } from './formatter';

@Injectable({
  providedIn: 'root',
})
export class FireBaseService {
  userData!: any;

  @Output() login = new EventEmitter<boolean>();
  constructor(
    public db: AngularFirestore,
    private storage: AngularFireStorage,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    public _format: FormatterService
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user')!);
      }
    });
  }
  uplodaFileToStorage(
    fileUpload: FileUpload,
    path: string
  ): Observable<number | undefined> {
    const filePath = `${path}/${fileUpload.file.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);
    uploadTask
      .snapshotChanges()
      .pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe((downloadURL) => {
            fileUpload.url = downloadURL;
            fileUpload.name = fileUpload.file.name;
          });
        })
      )
      .subscribe();

    return uploadTask.percentageChanges();
  }

  deleteFileStorage(path: string): void {
    const storage = getStorage();

    const strRef = ref(storage, path);

    deleteObject(strRef);
  }

  getFirbaseStorage() {
    return this.storage.storage.ref();
  }

  getFiles(path: string) {
    const storage = getStorage();

    // Create a reference under which you want to list
    const listRef = ref(storage, path);

    let files: any;

    // Find all the prefixes and items.
    files = listAll(listRef)
      .then((res) => res.items)
      .catch((error) => {
        console.log(error);
      });

    return files;
  }

  SendEmailVerification(email: string) {
    return this.afAuth.currentUser.then((user) => {
      user?.sendEmailVerification();
    });
  }

  SignIn(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  SignOut() {
    return this.afAuth.signOut().then((_) => true);
  }

  SetUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.db.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
    };

    return userRef.set(userData, {
      merge: true,
    });
  }

  get isLoggedIn(): boolean {
    const user = localStorage.getItem('user');

    return user !== null ? true : false;
  }

  async updateEdd(identity: string, formData: object) {
    return this.db
      .collection('Identities')
      .doc(identity)
      .collection('edd')
      .add(formData)
      .then((_) => true)
      .catch((e) => {
        throw e;
      });
  }

  async updateIdentity(identity: string, attribute: string, data: any) {
    this.db
      .collection('Identities')
      .doc(identity)
      .update({ [attribute]: data })
      .catch((err) => console.log(err));
  }

  updateNotes(identity: string, notes: Notes[]) {
    this.db.collection('Identities').doc(identity).update({ notes });
  }

  async updateMachineLogs(id: string, logs: MachineLog[]) {
    this.db
      .collection('Machine Configurations')
      .doc(id)
      .set({ logs }, { merge: true });
  }

  async updateLocation(locationData: any, id: string) {
    this.db
      .collection('Machine Configurations')
      .doc(id)
      .collection('Coin ATM Radar')
      .doc('Radar Data')
      .set(
        {
          Location: locationData,
        },
        { merge: true }
      );
  }

  async updateMachine(id: string, data: any, attribute: string) {
    this.db
      .collection('Machine Configurations')
      .doc(id)
      .set(
        {
          [attribute]: data,
        },
        { merge: true }
      );
  }

  async updateMachineV8Attribute(
    org: string,
    id: string,
    data: any,
    attribute: string
  ) {
    this.db
      .collection('SMetadataV8')
      .doc(org)
      .set(
        {
          machines: {
            [id]: {
              [attribute]: data,
            },
          },
        },
        { merge: true }
      );
  }

  async updateBlocklistLogs(data: any) {
    this.db.collection('Security').doc('Blocklist').set(
      {
        logs: data,
      },
      { merge: true }
    );
  }

  updateBlocklist(document: string, data: any, dataset: string) {
    this.db
      .collection('Security')
      .doc('Blocklist')
      .collection(dataset)
      .doc(document)
      .set(data, { merge: true });
  }

  updateTierConfig(limits: any, docIds: string[]) {
    for (const docId of docIds) {
      this.db
        .collection('SConfigV8')
        .doc(docId)
        .collection('Config')
        .doc('Config')
        .set({ limits }, { merge: true });
    }
  }

  addBlocklist(document: string, data: any, dataset: string) {
    this.db
      .collection('Security')
      .doc('Blocklist')
      .collection(dataset)
      .doc(document)
      .set(data);
    if (dataset != 'Identities') {
      this.db
        .collection('Security')
        .doc('Blocklist')
        .collection(dataset)
        .doc('Metadata')
        .set({ 'Last Assigned ID': document }, { merge: true });
    }
  }

  deleteBlockList(document: string, dataset: string) {
    this.db
      .collection('Security')
      .doc('Blocklist')
      .collection(dataset)
      .doc(document)
      .delete();
  }

  async getBlocklistLogs() {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Security')
        .doc('Blocklist')
        .valueChanges()
        .subscribe((users) => resolve(users));
    });
  }

  async getBlacklistLogs() {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Security')
        .doc('Blacklist')
        .collection('Logs')
        .valueChanges({ idField: 'Dataset' })
        .subscribe((users) => resolve(users));
    });
  }

  async getLocation(id: string) {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Machine Configurations')
        .doc(id)
        .collection('Coin ATM Radar')
        .doc('Radar Data')
        .valueChanges()
        .subscribe((users) => resolve(users));
    });
  }

  async getCTRTransactions() {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Valid CTRs')
        .valueChanges({ idField: 'docId' })
        .subscribe((ctrs) => resolve(ctrs));
    });
  }

  async getErroredTransactions() {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Trans')
        .doc('Errored TXs')
        .collection('List')
        .valueChanges({ idField: 'docId' })
        .subscribe((txs) => resolve(txs));
    });
  }

  async getPendingTransactions() {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Trans')
        .doc('Pending Transactions')
        .valueChanges({ idField: 'docId' })
        .subscribe((txs) => resolve(txs));
    });
  }

  async getAcceptedCoins() {
    return new Promise<any>((resolve) => {
      // @ts-ignore
      this.db
        .collection('SConfigV8')
        .doc('00001-001')
        .collection('Config')
        .doc('Config')
        .valueChanges({ idField: 'docId' })
        .subscribe((config) => {
          resolve(config!['trans_server_coins']);
        });
    });
  }

  async getOrgs() {
    return new Promise<any>((resolve) => {
      this.db
        .collection('SConfigV8')
        .valueChanges({ idField: 'docId' })
        .subscribe((users) => resolve(users));
    });
  }

  async getBlocklisted(list: string) {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Security')
        .doc('Blocklist')
        .collection(list)
        .valueChanges({ idField: 'Id' })
        .subscribe((users) => resolve(users));
    });
  }

  async getKYCData(org: string) {
    return new Promise<any>((resolve) => {
      this.db
        .collection('SConfigV8')
        .doc(org)
        .collection('Config')
        .doc('Config')
        .valueChanges()
        .subscribe((users) => resolve(users));
    });
  }

  async getIdentities(query: string, value: string) {
    if (query === 'all') {
      return new Promise<any>((resolve) => {
        this.db
          .collection('Identities')
          .valueChanges({ idField: 'Id' })
          .subscribe((users) => resolve(users));
      });
    } else if (query === 'one') {
      return new Promise<any>((resolve) => {
        this.db
          .collection('Identities')
          .doc(value)
          .valueChanges({ idField: 'Id' })
          .subscribe((users) => resolve(users));
      });
    } else {
      return;
    }
  }

  async getOFACLog() {
    return new Promise<any>((resolve) => {
      this.db
        .collection('OFAC Logs')
        .valueChanges({ idField: 'id' })
        .subscribe((users) => resolve(users));
    });
  }

  async getEddData(id: string) {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Identities')
        .doc(id)
        .collection('edd')
        .valueChanges()
        .subscribe((edd) => resolve(edd));
    });
  }

  async getCashboxLog(machId: string) {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Machine Configurations')
        .doc(machId)
        .collection('Cashbox Logs')
        .valueChanges()
        .subscribe((logs) => {
          const cashboxLogs: MachineLog[] = [];
          logs.forEach(function (log) {
            const cashboxData = log['Cashbox'];
            const cashlog: MachineLog = {
              Timestamp: log['Datetime'],
              Type: 'cashbox',
              BillCount: cashboxData['Bill Count'],
              FiatTotal: cashboxData['Fiat Total'],
            };
            cashboxLogs.push(cashlog);
          });
          const data = {
            Type: 'Cashbox',
            Content: cashboxLogs,
          };
          resolve(data);
        });
    });
  }

  async getAllCashboxLogs() {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Cashbox Logs')
        .valueChanges({ idField: 'docID' })
        .subscribe((users) => resolve(users));
    });
  }

  async getTransactions() {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Transactions')
        .valueChanges({ idField: 'id' })
        .subscribe((users) => resolve(users));
    });
  }

  async getStoredTransactions() {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Stored Transactions')
        .valueChanges({ idField: 'id' })
        .subscribe((users) => resolve(users));
    });
  }

  async getTxById(id: string) {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Transactions')
        .doc(id)
        .valueChanges()
        .subscribe((users) => resolve(users));
    });
  }

  async getMachines() {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Machine Configurations', (ref) =>
          ref.where('org', 'in', ['88888', '00001'])
        )
        .valueChanges({ idField: 'docId' })
        .subscribe((users) => resolve(users));
    });
  }

  async isMachineOnline(lastUpdated: any) {
    const currentDate = new Date().getTime();
    if (typeof lastUpdated === 'string') {
      lastUpdated = +lastUpdated;
    }
    const tenMinPrev = currentDate - 10 * 60 * 1000;
    if (typeof lastUpdated === 'string') {
      const dateParts = lastUpdated.split('-');
      lastUpdated =
        dateParts[0].length != 4
          ? new Date(lastUpdated).getTime()
          : new Date(
              `${dateParts[1]}-${dateParts[2]}-${dateParts[0]} ${dateParts[3]}`
            ).getTime();
    } else if (typeof lastUpdated === 'undefined') {
      lastUpdated = 0;
    } else {
      lastUpdated = lastUpdated * 1000;
    }

    return lastUpdated > tenMinPrev;
  }

  async getMachine(machId: string) {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Machine Configurations')
        .doc(machId)
        .valueChanges()
        .subscribe(async (mach: any) => {
          const last_updated =
            typeof mach.last_updated !== 'undefined'
              ? mach.last_updated
              : typeof mach['Last Updated'] !== 'undefined'
              ? mach['Last Updated']
              : 0;
          const isOnline = await this.isMachineOnline(last_updated);
          const inActive =
            typeof mach.inactive !== 'undefined' ? false : mach.inactive;
          const machine: Machine = {
            PrettyName: mach.pretty_name,
            Id: machId,
            BillCount: mach.acceptor_bill_count,
            FiatTotal: 0,
            LastUpdated: this._format.formatUnixTimestamp(last_updated),
            StatusSymbol: isOnline ? 'check_circle' : 'report_problem',
            Tooltip: isOnline ? 'Online' : 'Offline',
            Periods: [],
            LastEmptied: this._format.formatUnixTimestamp(
              typeof mach['Cashbox Logs'] === 'undefined'
                ? 0
                : mach['Cashbox Logs'][0].Datetime
            ),
            TotalTX: [],
            ServerId: '001',
            Balance: mach.acceptor_fiat_total,
            InActive: inActive,
            Logs: typeof mach.logs === 'undefined' ? [] : mach.logs,
            Operator:
              typeof mach.operator === 'undefined' ? 'None' : mach.operator,
            CashboxLogs: mach['Cashbox Logs'],
          };
          const data = {
            Type: 'Machine',
            Content: machine,
          };
          resolve(data);
        });
    });
  }

  async getMachinesV8() {
    return new Promise<any>((resolve) => {
      this.db
        .collection('SMetadataV8')
        .doc('00001-001')
        .valueChanges()
        .subscribe((users) => resolve(users));
    });
  }

  async getIDPhotos(id: string) {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Identities')
        .doc(id)
        .collection('Photos')
        .valueChanges()
        .subscribe((users) => resolve(users));
    });
  }

  async getPeriodTransactions(
    LastEmptied: string,
    endDate: string,
    id: string
  ) {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Machine Configurations')
        .doc(`metadata`)
        .valueChanges()
        .subscribe((users) => resolve(users));
    });
  }

  async getMachineTX(machId: string) {
    const transactions = await this.getTransactions();
    const totalTX: Transactions[] = [];
    let totalFiat = 0;
    for (const index in transactions) {
      if (typeof transactions[index].TXID === 'undefined') {
        continue;
      }
      const txid: string = transactions[index].TXID;
      if (typeof transactions[index].Crypto === 'undefined') {
        continue;
      }
      if (transactions[index]['Machine ID'] == machId) {
        if (typeof transactions[index]['Fiat Entered'] != 'undefined') {
          totalFiat += +transactions[index]['Fiat Entered'];
        }
        const tx: Transactions = {
          TXID: transactions[index].TXID,
          Date: this._format.formatUnixTimestamp(transactions[index].timestamp),
          Timestamp: transactions[index].timestamp,
          PrettyName: machId,
          MachineId: transactions[index]['Machine ID'],
          Crypto: transactions[index].Crypto,
          CryptoPurchased: transactions[index]['Crypto Purchased'],
          FiatEntered: transactions[index]['Fiat Entered'],
          MinFee: transactions[index]['Fee - Minimum'],
          NetFee: transactions[index]['Fee - Network'],
          OpFee: transactions[index]['Fee - Operator'],
          Destination: transactions[index]['Wallet Address'],
          Identity: transactions[index].Identity,
          Mode: transactions[index]['Transaction Mode'],
        };
        totalTX.push(tx);
      }
    }
    const totals = {
      TotalTXs: totalTX,
      TotalFiat: totalFiat,
    };
    const data = {
      Type: 'TX',
      Content: totals,
    };

    return data;
  }

  async getIdentity(id: string) {
    return this.getIdentities('one', id).then((iden) => {
      const ofac: OFAC = {
        OFACLogs: [],
        Passed: iden['Passed OFAC Check'],
      };
      const lastTx = this._format.formatUnixTimestamp(iden.last_tx_time);
      const identity: Identity = {
        Id: iden.Id,
        Name: iden.name,
        Address: iden.address,
        Phone: iden.phone_num,
        Alts: iden.alts,
        DailyLim:
          typeof iden['Limit Cache'] === 'undefined'
            ? iden.daily_limit
            : iden['Limit Cache'].Buying.Daily,
        DailyLims:
          typeof iden['Limit Cache'] === 'undefined'
            ? iden.daily_limit_s
            : iden['Limit Cache'].Selling.Daily,
        LastTXTime: lastTx,
        MonthlyLim:
          typeof iden['Limit Cache'] === 'undefined'
            ? iden.monthly_limit
            : iden['Limit Cache'].Buying.Monthly,
        MonthlyLims:
          typeof iden['Limit Cache'] === 'undefined'
            ? iden.monthly_limit_s
            : iden['Limit Cache'].Selling.Monthly,
        Licenses: iden.licenses,
        SSN: iden.ssn,
        Tier:
          typeof iden['Limit Cache'] === 'undefined'
            ? iden.tier
            : iden['Limit Cache'].Tier,
        Transactions: iden.transactions,
        Created:
          typeof iden.created === 'number'
            ? this._format.formatUnixTimestamp(iden.created)
            : iden.created,
        Notes: typeof iden.notes === 'undefined' ? [] : iden.notes,
        Photos: iden.photos,
        OFAC: ofac,
        Flag: typeof iden.flag === 'undefined' ? 'green' : iden.flag,
        Frozen: typeof iden.frozen === 'undefined' ? false : iden.frozen,
        MergedIds: typeof iden.mergedIds === 'undefined' ? [] : iden.mergedIds,
        Email: typeof iden.email === 'undefined' ? '' : iden.email,
      };

      return identity;
    });
  }

  async getIdentityPhotos(id: string) {
    return new Promise<any>((resolve) => {
      this.db
        .collection('Identities')
        .doc(id)
        .collection('Photos')
        .valueChanges({ idField: 'timestamp' })
        .subscribe((photos) => resolve(photos));
    });
  }
}
