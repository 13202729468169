import { NgModule } from '@angular/core';
import { SnackbarService } from '../../../services/snackbar.service';
import { MonthPickerComponent } from './monthly-picker.component';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [MatInputModule, MatDatepickerModule, FormsModule],
  exports: [MonthPickerComponent],
  declarations: [MonthPickerComponent],
  providers: [SnackbarService],
})
export class MonthlyPickerModule {}
