import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  duration = 3000;

  constructor(private snackBar: MatSnackBar) {}

  /**
   * Shows a success snackbar
   * @param message
   * @param action
   * @param duration
   */
  onShowSnackbarSuccess(message: string, action?: string, duration?: number) {
    return this._showSnackbar(
      message,
      'success-snackbar',
      action ?? '',
      duration ?? this.duration
    );
  }

  /**
   * Shows an error snackbar
   * @param message
   * @param action
   * @param duration
   */
  onShowSnackbarError(message: string, action?: string, duration?: number) {
    return this._showSnackbar(
      message,
      'error-snackbar',
      action ?? '',
      duration ?? this.duration
    );
  }

  /**
   * Shows the snackbar
   * @param message
   * @param panelClass
   * @param duration
   * @param action
   */
  _showSnackbar(
    message: string,
    panelClass: string,
    action: string,
    duration: number
  ) {
    return this.snackBar.open(message, action, {
      duration,
      panelClass: [panelClass],
    });
  }
}
