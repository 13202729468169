import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/app-comp/src/environments/environment';

@Component({
  selector: 'app-manage-machines',
  templateUrl: './manage-machines.component.html',
  styleUrls: ['./manage-machines.component.css'],
})
export class ManageMachinesComponent implements OnInit {
  machines: any[] = [];
  locations: any[] = [];
  isLoading: boolean = true;
  isModalOpen: boolean = false; // Tracks modal state
  selectedMachine: any = null; // Holds the currently selected machine for editing

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getMachines();
    this.getlocations();
  }

  getMachines(): void {
    const apiUrl = `${environment.fgSqlApiUrl}getMachines`;
    this.http.get<any[]>(apiUrl).subscribe({
      next: (machines) => {
        this.machines = machines;
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Failed to load machines:', error);
        this.isLoading = false;
      },
    });
  }

  convertTimestampToDate(timestamp: number): string {
    const date = new Date(timestamp * 1000);
    // Example: 'en-US' locale, options for longer date format
    return date.toLocaleDateString('en-US', {
      year: 'numeric', // "2021"
      month: 'short', // "July"
      day: 'numeric', // "20"
    });
  }

  getStatusColor(machine: any): string {
    if (machine.active) {
      if (machine.machine_status != 'not-specified') {
        return 'blue'; // Maintanance
      } else if (machine.jammed || machine.acceptor_bill_count == 0) {
        return 'red'; // Critical Status things like machine jam, no money, full on money, hasn't pinged in some time, etc
      } else if (machine.acceptor_bill_count <= 5) {
        return 'gold'; // Problem Status things like low or high on funds, no collector assigned, etc
      } else {
        return 'green'; // Good Status
      }
    } else {
      return 'grey'; // Default color if status is unrecognized
    }
  }

  getlocations() {
    const apiUrl = `${environment.fgSqlApiUrl}getLocations`;
    this.http.get<any[]>(apiUrl).subscribe({
      next: (locations) => {
        this.locations = locations;
        console.log(locations);
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Failed to load machines:', error);
        this.isLoading = false;
      },
    });
  }

  findAddressByLocationId(locationId: number): string {
    const location = this.locations.find((loc) => loc.id === locationId);
    return location
      ? `${location.street_1}, ${location.city}, ${location.state}`
      : 'Address not found';
  }

  openModal(machine: any): void {
    this.selectedMachine = machine;
    this.isModalOpen = true;
  }

  closeModal(): void {
    this.isModalOpen = false;
  }

  updateMachine(): void {
    console.log('Updated Machine:', this.selectedMachine);
    // Here you would typically make an HTTP request to update the machine on the server
    this.closeModal();
  }
}
