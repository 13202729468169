import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/app-comp/src/environments/environment';
import { SQLUser } from '../../../interfaces/sql_entities';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of, forkJoin, Observable } from 'rxjs';
import { FormatterService } from '../../../services/formatter';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css'],
})
export class ManageUsersComponent implements OnInit {
  users: SQLUser[] = [];
  isAddUserModalOpen: boolean = false;
  searchTerm: string = '';
  isLoading: boolean = false;
  selectedUser: SQLUser | null = null;
  userDetailsForm!: FormGroup;
  sortField: string = 'display_name';
  sortOrder: 'asc' | 'desc' = 'asc';
  filteredUsers: SQLUser[] = [];
  selectedRoleFilter: string = '';
  displayUsers: SQLUser[] = [];
  isModalOpen: boolean = false;
  modalContext: 'add' | 'edit' = 'add';
  organizations: any[] = [];
  isLoadingAdd: boolean = false;
  isLoadingEdit: boolean = false;
  isLoadingDelete: boolean = false;

  roleDescriptions: { [key: string]: string } = {
    org: 'Organizer',
    coll: 'Collector',
    comp: 'Compliance',
    admin: 'God',
  };

  cryptoImages = [
    { src: 'assets/img/btc.png', alt: 'Bitcoin' },
    { src: 'assets/img/doge.png', alt: 'Dogecoin' },
    //{ src: 'assets/img/eth.png', alt: 'Ethereum' },
    { src: 'assets/img/ltc.png', alt: 'Litecoin' },
    //{ src: 'assets/img/usdc.png', alt: 'USDC' },
    { src: 'assets/img/xmr.png', alt: 'Monero' },
  ];

  roles = [
    { code: 'org', description: 'Organizer' },
    { code: 'coll', description: 'Collector' },
    { code: 'comp', description: 'Compliance' },
    { code: 'admin', description: 'God' },
  ];

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private formatter: FormatterService
  ) {
    this.userDetailsForm = this.fb.group({
      firstName: ['', Validators.required], // First name field
      lastName: ['', Validators.required], // Last name field
      email: ['', [Validators.required, Validators.email]],
      phone_num: ['', [Validators.required]],
      org_id: ['', Validators.required],
      role: ['', Validators.required],
      enable_crypto: [false],
    });
  }

  public formatPhone(phone: string): string {
    return this.formatter.formatPhoneNumber(phone);
  }

  // Modify ngOnInit to include organization fetching
  ngOnInit(): void {
    this.isLoading = true;
    this.getOrganizations();
    this.fetchUsers();
  }

  fetchUsers(): void {
    //this.isLoading = true;  // Show loading indicator for the table
    this.http.get<SQLUser[]>(`${environment.fgSqlApiUrl}getUsers`).subscribe({
      next: (users) => {
        this.users = users;
        this.filterUsers(); // Apply any existing filters
        this.isLoading = false; // Hide loading indicator
      },
      error: (err) => {
        console.error('Failed to load users:', err);
        this.isLoading = false; // Hide loading indicator
      },
    });
  }

  filterUsers(): void {
    let results = this.users;

    // Filter by selected role if any
    if (this.selectedRoleFilter) {
      results = results.filter((user) => user.role === this.selectedRoleFilter);
    }

    // Filter by search term if provided
    if (this.searchTerm) {
      results = results.filter(
        (user) =>
          user.display_name
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }

    // Sort results
    results.sort((a, b) => {
      let valueA = (a[this.sortField] || '').toLowerCase(); // Ensure lowercase comparison
      let valueB = (b[this.sortField] || '').toLowerCase();
      return this.sortOrder === 'asc'
        ? valueA > valueB
          ? 1
          : -1
        : valueA < valueB
        ? 1
        : -1;
    });

    this.filteredUsers = results;
  }

  setSortField(field: 'display_name' | 'email'): void {
    if (this.sortField === field) {
      this.toggleSortOrder();
    } else {
      this.sortField = field;
      this.sortOrder = 'asc'; // Reset to ascending when changing fields
    }
    this.filterUsers(); // Ensure filterUsers is called here
  }

  toggleSortOrder(): void {
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    this.filterUsers(); // Call filterUsers after toggling the order
  }

  updateUser(uid: string, dataFields: any): Observable<any> {
    const payload = {
      uid,
      dataFields,
    };
    return this.http.post(`${environment.fgSqlApiUrl}updateUser`, payload);
  }

  deselectUser(): void {
    this.selectedUser = null;
  }

  getRoleDescription(shortRole: string): string {
    return this.roleDescriptions[shortRole] || shortRole; // Fallback to the short role if no mapping found
  }

  saveChanges(): void {
    if (!this.selectedUser) {
      console.error('No user selected');
      return; // Early return to stop execution if there is no selected user
    }

    const uid = this.selectedUser.uid;
    const dataFields = {
      displayName: this.selectedUser['displayName'],
      // Add other fields to update
    };

    /*this.updateUser(uid, dataFields).subscribe({
      next: (result) => console.log('Update successful:', result),
      error: (error) => console.error('Update failed:', error)
    });*/
  }

  addUser(): void {
    if (this.userDetailsForm.valid) {
      this.isLoadingAdd = true;
      const url = `https://us-central1-master-firebase-425a3.cloudfunctions.net/createUser`;
      this.http.post(url, { userData: this.userDetailsForm.value }).subscribe({
        next: (response) => {
          console.log('User created successfully:', response);
          this.fetchUsers();
          this.closeModal(); // Optionally close the modal on success
          this.isLoadingAdd = false;
        },
        error: (error) => {
          console.error('Failed to create user:', error);
          this.isLoadingAdd = false;
        },
      });
    } else {
      alert('Please fill in all required fields.');
    }
  }

  editUser(): void {
    if (this.userDetailsForm.valid) {
      if (!this.selectedUser) {
        console.error('No user selected');
        return; // Early return to stop execution if there is no selected user
      }
      this.isLoadingEdit = true;

      const uid = this.selectedUser.uid;
      const formData = this.userDetailsForm.value;
      const dataFields = {
        display_name: `${formData.firstName} ${formData.lastName}`, // Assuming the name is split into first and last
        email: formData.email,
        phone_num: formData.phone_num,
        role: formData.role,
        enable_crypto: formData.enable_crypto,
        org_id: formData.org_id,
      };

      this.updateUser(uid, dataFields).subscribe({
        next: (result) => {
          console.log('Update successful:', result);
          this.fetchUsers();
          this.closeModal(); // Close modal on successful update
          this.isLoadingEdit = false;
        },
        error: (error) => {
          console.error('Update failed:', error);
          alert('Failed to update user.'); // Show error to the user
          this.isLoadingEdit = false;
        },
      });
    } else {
      alert('Please fill in all required fields.');
    }
  }

  openModal(context: 'add' | 'edit', user?: SQLUser): void {
    this.modalContext = context;
    this.isModalOpen = true;
    if (context === 'edit' && user) {
      this.selectedUser = user;
      this.userDetailsForm.setValue({
        firstName: user.display_name.split(' ')[0] || user.display_name,
        lastName: user.display_name.split(' ')[1] || '',
        email: user.email,
        phone_num: user.phone_num,
        role: user.role,
        org_id: user.org_id,
        enable_crypto: user.enable_crypto || false,
      });
    } else {
      this.userDetailsForm.reset({
        firstName: '',
        lastName: '',
        email: '',
        phone_num: '',
        role: '',
        org_id: '',
        enable_crypto: false,
      });
    }
    this.isModalOpen = true;
  }

  closeModal(): void {
    this.isModalOpen = false;
    this.selectedUser = null;
  }

  deleteUser(event: MouseEvent): void {
    event.stopPropagation(); // Prevents the event from bubbling up to the row's click handler

    if (confirm('Are you sure you want to delete this user?')) {
      this.isLoadingDelete = true;
      const url =
        'https://us-central1-master-firebase-425a3.cloudfunctions.net/deleteUser';
      this.http.post(url, { uid: this.selectedUser!.uid }).subscribe({
        next: (response) => {
          console.log('User deleted successfully:', response);
          this.fetchUsers();
          this.closeModal(); // Optionally close the modal on success
          // Additional logic for success response
          this.isLoadingDelete = false;
        },
        error: (error) => {
          console.error('Failed to delete user:', error);
          this.isLoadingDelete = false;
        },
      });
      console.log('User deleted:', this.selectedUser);
      // Optionally, splice the user from filteredUsers to update the UI
      /*const index = this.filteredUsers.indexOf(user);
      if (index > -1) {
        this.filteredUsers.splice(index, 1);
      }*/
    }
  }

  resendEmail(): void {
    console.log('Resending verification email for user:', this.selectedUser);
    const url =
      'https://us-central1-master-firebase-425a3.cloudfunctions.net/resendVerificationEmail';
    const body: any = {
      uid: this.selectedUser!.uid,
      email: this.selectedUser!.email,
    };

    this.http.post(url, { userData: body }).subscribe({
      next: (response) => {
        console.log('Verification email resent successfully', response);
      },
      error: (error) => {
        console.error('Error resending verification email:', error);
      },
    });
  }

  convertTimestampToDate(timestamp: number): string {
    const date = new Date(timestamp * 1000);
    // Example: 'en-US' locale, options for longer date format
    return date.toLocaleDateString('en-US', {
      year: 'numeric', // "2021"
      month: 'short', // "July"
      day: 'numeric', // "20"
    });
  }

  getOrganizations(): void {
    const url = `${environment.fgSqlApiUrl}/getOrganizations`;
    this.http.get<any[]>(url).subscribe({
      next: (data) => {
        this.organizations = data;
      },
      error: (error) => {
        console.error('Error fetching organizations:', error);
      },
    });
  }
}
