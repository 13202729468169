<!-- manage-machines.component.html -->
<div *ngIf="isLoading">Loading machines...</div>

<div *ngIf="!isLoading">
  <div *ngFor="let machine of machines" class="machine-card">
    <div class="top-row">
      <p class="pretty-name">
        <strong>{{ machine.pretty_name }}</strong>
      </p>
      <!-- Make Larger -->
      <p class="last-updated">
        <strong>Last Updated:</strong>
        {{ convertTimestampToDate(machine.last_updated) }}
      </p>
    </div>
    <div class="middle-row">
      <p class="address">
        <strong>Address:</strong>
        {{ findAddressByLocationId(machine.location_id) }}
      </p>
      <!--<div
        class="status-indicator"
        [ngStyle]="{ 'background-color': getStatusColor(machine) }"
      ></div>-->
    </div>
    <div class="bottom-row">
      <p><strong>Machine ID:</strong> {{ machine.machine_id }}</p>
      <p class="status">
        <strong>Balance:</strong> ${{ machine.acceptor_fiat_total }}
      </p>
    </div>
  </div>
</div>
