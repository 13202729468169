import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { HttpClient } from '@angular/common/http';

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-manage-organizations',
  templateUrl: './manage-organizations.component.html',
  styleUrls: ['./manage-organizations.component.css'],
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '0px',
          opacity: 0,
          overflow: 'hidden',
        })
      ),
      state(
        'expanded',
        style({
          height: '*', // This ensures the element takes up as much space as needed
          opacity: 1,
        })
      ),
      transition('collapsed <=> expanded', animate('300ms ease-in-out')),
    ]),
  ],
})
export class ManageOrganizationsComponent implements OnInit {
  public organizationDetails: any = null;
  isLoading: boolean = false;
  public editForm: FormGroup;
  isModalOpen: boolean = false;
  modalContext!: 'address' | 'owner';
  showUsers: boolean = false;
  showMachines: boolean = false;
  //get user's org ID
  //get org by org ID
  //output org details

  constructor(private http: HttpClient, private fb: FormBuilder) {
    this.editForm = this.fb.group({});
  }

  toggleUsersVisibility(): void {
    this.showUsers = !this.showUsers;
  }

  toggleMachinesVisibility(): void {
    this.showMachines = !this.showMachines;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.getOrg();
  }

  getOrg(): void {
    const url = `https://us-central1-master-firebase-425a3.cloudfunctions.net/getOrganizationById?id=1`;
    this.http.get(url).subscribe({
      next: (response) => {
        this.organizationDetails = response;
        console.log('Organization Details:', this.organizationDetails[0]);
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error fetching organization details:', error);
      },
    });
  }

  openModal(context: 'address' | 'owner', details?: any): void {
    this.modalContext = context;
    this.isModalOpen = true;
    console.log(details);
    if (context === 'address' && details) {
      this.editForm = this.fb.group({
        street_1: [details.street_1, Validators.required],
        street_2: [details.street_2, Validators.required],
        city: [details.city, Validators.required],
        state: [details.state, Validators.required],
        postal_code: [details.postal_code, Validators.required],
      });
    } else if (context === 'owner' && details) {
      this.editForm = this.fb.group({
        owner_firstname: [details.owner_firstname, Validators.required],
        owner_lastname: [details.owner_lastname, Validators.required],
        owner_phone_num: [details.owner_phone_num, Validators.required],
      });
    }
  }

  submitEdit(): void {
    if (this.editForm.valid) {
      /*const updateUrl = `https://us-central1-master-firebase-425a3.cloudfunctions.net/updateOrganizationById?id=${this.organizationDetails.id}`;
      this.http.post(updateUrl, this.editForm.value).subscribe({
        next: (response) => {
          console.log('Organization updated successfully');
          this.getOrg();  // Refresh the organization details
          this.isModalOpen = false;  // Close the modal
        },
        error: (error) => {
          console.error('Failed to update organization:', error);
        },
      });*/
    } else {
      alert('Please ensure all fields are filled correctly.');
    }
  }

  editAddress(details: any): void {
    // Open modal or form to edit address
    console.log('Editing address for:', details);
  }

  editOwner(details: any): void {
    // Open modal or form to edit owner details
    console.log('Editing owner for:', details);
  }
}
