<mat-form-field appearance="outline">
  <input
    matInput
    [(ngModel)]="monthAndYear"
    [matDatepicker]="picker"
    (dateChange)="emitDateChange($event)"
    (focus)="picker.open()"
    placeholder="Month"
  />
  <mat-hint>MM/YYYY</mat-hint>
  <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
  <mat-datepicker
    #picker
    [startView]="'year'"
    (monthSelected)="monthChanged($event, picker)"
  >
  </mat-datepicker>
</mat-form-field>
