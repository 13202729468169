import { Injectable } from '@angular/core';
import { keys, map, sum } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class FormatterService {
  formatUnixTimestamp(timestamp: any) {
    if (!isNaN(timestamp)) {
      timestamp = +timestamp;
    } else {
      if (
        (typeof timestamp === 'number' && timestamp == 0) ||
        typeof timestamp === 'undefined'
      ) {
        return 'Not Updated';
      }

      return timestamp;
    }
    const date = new Date(timestamp * 1000);

    const year = date.getFullYear();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // 👇️ Format as hh:mm:ss
    const formattedDate = `${year}-${this.padTo2Digits(
      month
    )}-${this.padTo2Digits(day)}-${this.padTo2Digits(
      hours
    )}:${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;

    return formattedDate;
  }

  formatPhoneNumber(phoneNumberString: string) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';

      return [intlCode, '(', match[2], ') ', match[3], ' - ', match[4]].join(
        ''
      );
    }

    return phoneNumberString;
  }

  padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  displayMachineId(input: string): string {
    // Split the input string by hyphens
    const parts = input.split('-');
    // Check if there are at least two parts to extract
    if (parts.length >= 2) {
      // Get the last two parts
      const lastTwoParts = parts.slice(-2);
      // Join them with a hyphen and return
      return lastTwoParts.join('-');
    } else {
      // Handle cases where the input format is unexpected
      throw new Error('Input string does not have the expected format.');
    }
  }

  getDispenserInvForMachine(machine: any, prettyPrint = false) {
    const totalInv = sum(
      map(keys(machine), (key) => {
        if (key.includes('cassette_count')) {
          return (
            machine[key] *
            machine[key.replace('cassette_count', 'cassette_denom')]
          );
        } else {
          return 0;
        }
      })
    );
    return totalInv;
  }

  formatDispenserInvForMachine(machine: any) {
    return map(keys(machine), (key) => {
      if (key.includes('cassette_count')) {
        const denomKey = key.replace('cassette_count', 'cassette_denom');
        return {
          Denomination: machine[denomKey],
          Quantity: machine[key],
        };
      } else {
        return undefined;
      }
    }).filter((item) => item !== undefined); // Filter out undefined items
  }
}
