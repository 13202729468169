import { Component } from '@angular/core';
import { AuthGuard } from '../../services/auth.guard';
import { ToolbarService } from '../../toolbar.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
})
export class ToolbarComponent {
  user: any;

  constructor(public toolbar: ToolbarService, public authService: AuthGuard) {
    this.user = JSON.parse(localStorage.getItem('user')!);
    if (this.user != null) {
      // toolbar.showSideNav = true;
      toolbar.showToolbar = true;
    }
  }

  toggleNav() {
    this.toolbar._showSideNav = !this.toolbar.showSideNav;
  }

  showMenu() {
    return typeof this.user !== null;
  }

  logout() {
    localStorage.removeItem('user');
    this.authService.logout();
  }
}
