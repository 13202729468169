import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { DisplayReports } from '../interfaces/displayReports';
import { DisplayTransactions } from '../interfaces/displayTx';
import { SQLReports, SQLTransactions } from '../interfaces/sql_entities';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor() {}

  public exportReportAsExcelFile(
    transactionsData: DisplayTransactions[],
    reportSummary: DisplayReports[],
    excelFileName: string
  ) {
    // Create a new workbook
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();

    // Create a worksheet from the transactions data
    const transactionsWorksheet: XLSX.WorkSheet =
      XLSX.utils.json_to_sheet(transactionsData);

    // Specify column widths (e.g., for the first and second columns)
    transactionsWorksheet['!cols'] = [
      { wch: 25 }, // 'wch' specifies the column width
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      // Add more column width specifications as needed
    ];

    // Assuming columns 4, 5, 6, 7, and 8 (D, E, F, G, H in Excel) need currency formatting
    const currencyColumns = [5, 6, 7, 8, 9]; // In zero-based index, column D is 3, E is 4, and so on

    // Apply currency formatting to specific cells in columns 4, 5, 6, 7, and 8 for each row
    transactionsData.forEach((_, rowIndex) => {
      currencyColumns.forEach((columnIndex) => {
        const cellAddress = XLSX.utils.encode_cell({
          c: columnIndex,
          r: rowIndex + 1,
        }); // +1 because Excel rows start at 1, but arrays start at 0
        const cell = transactionsWorksheet[cellAddress];
        if (cell) {
          cell.z = '"$"#,##0.00';
        }
      });
    });

    XLSX.utils.book_append_sheet(
      workbook,
      transactionsWorksheet,
      'Transactions'
    );

    // Create another worksheet from the report summary data
    const summaryWorksheet: XLSX.WorkSheet =
      XLSX.utils.json_to_sheet(reportSummary);
    summaryWorksheet['!cols'] = [
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];

    XLSX.utils.book_append_sheet(workbook, summaryWorksheet, 'Summary');

    // Write the workbook to a file
    XLSX.writeFile(workbook, `${excelFileName}.xlsx`);
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(myworkbook, myworksheet, excelFileName);
    const excelBuffer: any = XLSX.write(myworkbook, {
      bookType: 'xlsx',
      type: 'buffer',
    });
    XLSX.write(myworkbook, { bookType: 'xlsx', type: 'binary' });
    XLSX.writeFile(myworkbook, excelFileName + '.csv');
  }
}
